// extracted by mini-css-extract-plugin
export var adSpace250x = "article-wrapper-module--adSpace250x--f3nr9";
export var adSpace300x = "article-wrapper-module--adSpace300x--1KdmC";
export var adSpace728x90 = "article-wrapper-module--adSpace728x90--T4Bzz";
export var adSpace970x250 = "article-wrapper-module--adSpace970x250--Y9RJb";
export var heroAd = "article-wrapper-module--heroAd--vzCjS";
export var heroMiniAd = "article-wrapper-module--heroMiniAd--6v0W5";
export var listItem = "article-wrapper-module--listItem--Yev-x";
export var main = "article-wrapper-module--main--he3R+";
export var relatedTopics = "article-wrapper-module--relatedTopics--Dg9dl";
export var rightPanel = "article-wrapper-module--rightPanel--7TamL";
export var rightPanelPadded = "article-wrapper-module--rightPanelPadded---bi6j";
export var rightSubmenu = "article-wrapper-module--rightSubmenu--5WJeS";
export var root = "article-wrapper-module--root--45Mdi";
export var similarPages = "article-wrapper-module--similarPages--2ZEAK";
export var stickableArea = "article-wrapper-module--stickableArea--yEKZe";
export var stickyMenu = "article-wrapper-module--stickyMenu--nCQsx";